export default theme => ({
    disclaimer:{
        color: 'rgb(120, 120, 120)',
        [theme.breakpoints.up('xs')]: {
            fontSize: '8px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '12px',
        },
    },
    captionText:{
        color: '#505050 !important'
    },
    header:{
        lineHeight: '1.25',
        [theme.breakpoints.up('xs')]: {
            fontSize: '28px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '35px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '45px',
        },
        fontWeight: 800,
        letterSpacing: '0.1rem',
        color: 'rgb(29, 29, 31)'
    },
    header2:{
        lineHeight: '1.25',
        [theme.breakpoints.up('xs')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '22px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '30px',
        },
        fontWeight: 800,
        letterSpacing: '0.1rem',
        color: 'rgb(29, 29, 31)'
    },
    headerLight:{
        lineHeight: '1.25',
        [theme.breakpoints.up('xs')]: {
            fontSize: '28px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '35px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '45px',
        },
        fontWeight: 100,
        letterSpacing: '0.25rem',
        color: 'rgb(29, 29, 31)'
    },
    captionBody:{
        lineHeight: '1.5',
        [theme.breakpoints.up('xs')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '20px',
        },
        fontWeight: 350,
        color: 'rgb(29, 29, 31)'
    }
})
