import React from 'react';

import {
  NavLink,
  withRouter
} from "react-router-dom";

import classNames from 'classnames';

import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import Logo from 'expi/components/logo';
import Box from '@material-ui/core/Box';

import routes from 'routes';
import style from './Styles';
///
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";

///

function BurgerMenu(props) {

  const { classes, 
          location 
          /*, ...rest*/} = props
  
  const renderMenuItem = (route) => {
      if (route.menuItem) {
      return(
        <ListItem button key={route.path}>
              <NavLink
                      to={route.path} 
                      key={route.label}
                      className={ classNames({ 
                          [classes.menuItem]: true,
                          [classes.menuItemActive]: location.pathname === route.path
                      })}>
                  {route.label}
              </NavLink>
        </ListItem>)
      } 
  }

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);


  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
      <Box
        width={1}
        display="flex" 
        flexDirection="row" 
      > 
        <Box
          flexGrow={1}
        > 
          <ul>
            <NavLink to="/" className={classes.menuItem}>
              <Logo/>
            </NavLink>
          </ul>
        </Box>
        <Box 
          display="flex" 
          justifyContent={"center"}
          flexDirection={"column"} 
          mr={3}
          onClick={() => {
            toggleDrawer()
          }}
        > 
          <Box 
            width={'33px'} 
            height={'4px'} 
            mb={'4px'}
            className={classNames({ [classes.burgerLine]: true,
                                    [classes.burgerFirstLineActive]: isDrawerOpen})}/>
          <Box 
            width={'33px'} 
            height={'4px'} 
            mb={'4px'} 
            className={classNames({ [classes.burgerLine]: true,
                                    [classes.burgerMidLineActive]: isDrawerOpen})}/>
          <Box 
            width={'33px'} 
            height={'4px'} 
            mb={'4px'}
            className={classNames({ [classes.burgerLine]: true,
                                    [classes.burgerLastLineActive]: isDrawerOpen})}/>
        </Box>

        <SwipeableDrawer
          className={classes.drawer}
          anchor={"right"}
          open={isDrawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
            {routes.map(item => (
              renderMenuItem(item)
            ))}
            <ListItem button>
              <a href="mailto:info@expi.ai" className={classes.menuItem}>
                Contact Us
              </a>
            </ListItem>
        </List>
      </SwipeableDrawer>
      </Box>
  )
}

export default withWidth()(withRouter(withStyles(style)(BurgerMenu)));