import generalStyle from 'common/styles/general.js';

const style = theme => ({
      root: {
        flexGrow: 1,
      },
        ...generalStyle(theme)
    });

export default style;
