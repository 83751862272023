import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import image from 'images/logo+text.svg';

export default  withWidth()(class Default extends React.Component {
    render() {
        var res = ( <img height="30px" src={image} alt="logo" /> )
        
        
        if( isWidthUp('lg', this.props.width) )
        {
            res = ( <img height="35px" src={image} alt="Expi" /> )
        }
        return res
    }
})