import generalStyle from 'common/styles/general.js';

import womanImage from 'images/woman.png';
//import sphereImage from 'images/sphere.png';

const style = theme => ({
      root: {
        flexGrow: 1,
      },
      subtitle:{
        fontWeight: 150,
        paddingTop: 5,
        fontSize: '1.3rem',
        textAlign: 'center'
      },
      womanSphere: {
        position: 'fixed',
        top: '25px',
        left: '0px',
        minHeight: '60%',
        height: '60%',
        maxHeight: '60%', 
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'lighten',
        minWidth: '100%',
        width: '100%',
        zIndex: '-1'
      },
      womanCompact: {
        position: 'fixed',
        top: '40px',
        left: '0px',
        minHeight: '55%',
        height: '55%',
        maxHeight: '55%',
        backgroundImage: `url(${womanImage})`, 
        backgroundPosition: 'right top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'lighten',
        width: '100%',
        opacity: 1,
        zIndex: -2
      },
      /*
      sphereCompact: {
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        minHeight: '40%',
        height: '40%',
        maxHeight: '40%',
        backgroundImage: `url(${sphereImage})`, 
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'lighten',
        width: '100%',
        opacity: 1,
        zIndex: -2
      },*/
      loadingIndicator: {
        position : 'relative'
      },
      loadingIndicatorImage: {
        display: 'block'
      },
      compactOverlay: {
        backgroundImage: 'linear-gradient(to bottom, rgba(255, 255 , 255, 0) 20%, rgba(255, 255, 255, 0.9))',
        zIndex: -1,
        position: 'absolute',
        bottom: 0,
        top: 0,
        right: 0,
        left: 0,
        width: '100%',
        height: '100%'
      },
      loadingIndicatorOverlay: {
        background: 'rgba(255,255,255,.5)',
        zIndex: 10,
        position: 'absolute',
        bottom: 0,
        width: '100%'
      },
      topBarBreaker: {
        [theme.breakpoints.down('sm')]: {
            minHeight: '100px',
            height: '100px',
            maxHeight: '100px',
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: '150px',
            height: '150px',
            maxHeight: '150px',
        }
    },
      ...generalStyle(theme)
    });

export default style;
