
import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import style from './Styles';

function Footer (props) {
    const { classes,
            /*...rest*/ } = props; 

    return(
        <footer>
            <Box
                display={"flex"}
                flexDirection={"row"}
                flexGrow={"1"}
                justifyContent={"flex-end"}
                pr={1}
            >
                <Typography variant={"caption"} className={classes.disclaimer} align={"center"} gutterBottom>
                    Copyright © 2022 Beyond Expression LLC. All rights reserved.
                </Typography>
            </Box>
        </footer>)
}

export default withWidth()(withStyles(style)(Footer));