import React from 'react';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import {Switch} from 'react-router-dom';
import {Route} from 'react-router-dom';

import routes from 'routes';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 350,
      md: 600,
      lg: 900,
      xl: 1200
    }
  },
  typography: {
    fontFamily: [
      'SF Pro Text',
      'SF Pro Icons',
      'Helvetica Neue',
      'Helvetica',
      'Roboto', 
      'Arial',
      'sans-serif'
    ].join(','),
  },
  shadows: [
    "none",
    "0px 8px 18px #DFDFDF",
    "0px 8px 49px #C8C8C8",
    ...Array(22).fill('none')
  ]
});

function App() {
  return(
    <ThemeProvider theme={theme}>
      <Switch>
        {routes.map((route, index) => (
            <Route
              key={index}
              id={index}
              path={route.path}
              exact={route.exact}
              children={route.layout}
            />
          ))}
      </Switch>
    </ThemeProvider>)
}

export default App;
