import React from 'react';

import { withRouter } from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import style from './Styles';
import animationStyles from './Styles/animation.module.css'; 

function AboutView(props) {

    const { classes,
        width /*, ...rest*/ } = props

    const aboutText = (
        <Typography 
            align="justify"
            className={classes.captionBody}>
                At Beyond Expression we believe in the Colonization of Mars, Mining the Asteroid Belt, Humanized AI, Technological Singularity, and efficient Human - Machine Interfaces. At the same time, we are convinced that face to face social interaction is what brings meaning to an everyday life.
                <br/><br/>
                New channels of communication, globalization, and faster pace of living have brought an impact on our ability to project and perceive non-verbal components that is crucial for every human interaction.
                <br/><br/>
                We focus on applying our proprietary technology to enhance personal and business communications by analyzing, training, and guiding interaction with verbal and non-verbal insights. 
                <br/><br/>
                We are proud to introduce Expi™️ that provides actionable perception insights into day-to-day simulated conversations.
                <br/><br/>
        </Typography>
    )

    const stdContent = (
        <Box
            display="flex"
            flexDirection="row" 
            flexGrow={1}
            pm={'300px'}
            justifyContent="center">
        
            <Box width={0.2}
                m={3}
                display="flex"
                flexDirection="column" 
                justifyContent="flex-start"
                alignItems="flex-end">

                <Typography
                    className={classNames(classes.header, animationStyles.about)}>
                    ABOUT
                </Typography>
                <Typography
                    className={classNames(classes.headerLight, animationStyles.us)}>
                    US
                </Typography>
            </Box>
            
            <Box width={0.4}
                m={3}
                display="flex"
                flexDirection="column" 
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classNames(animationStyles.description)}>
        
                {aboutText}
            
            </Box>
        </Box>
    )

    const compactContent = (
        <Box 
            height={1} 
            m={2}
            pb={3}
            display="flex"
            flexDirection="column" 
            justifyContent="flex-start">
        
            <Box
                display="flex"
                flexDirection="row" 
                justifyContent="center"
                alignItems="flex-end">

                <Typography
                    className={classNames(classes.header, animationStyles.about)}>
                    ABOUT
                </Typography>
                <Typography
                    m={3}
                    className={classNames(classes.headerLight, animationStyles.us)}>
                    US
                </Typography>
            </Box>
            
            <Box
                m={3} 
                display="flex"
                flexDirection="row" 
                justifyContent="flex-start"
                className={classNames(animationStyles.description)}>
                
                {aboutText}
            
            </Box>
        </Box>
    )

    return ( isWidthUp('md', width) ? stdContent : compactContent )
}


export default withWidth()(withRouter(withStyles(style)(AboutView)));