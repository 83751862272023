import React from 'react';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import style from './Styles';

import LandingStandard from './standard';
import LandingCompact from './compact';


function LandingView(props) {
    const { width,
            /*...rest*/ } = props;

    return( isWidthUp('md', width) ? <LandingStandard/> : <LandingCompact/> )
}

export default withWidth()(withStyles(style)(LandingView))
