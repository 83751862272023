import React from 'react';

import {withRouter,
        Switch,
        Route} from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import style from './Styles';
import routes from 'routes';

import Menu from 'expi/components/menu';
import Footer from 'expi/components/footer';
import BurgerMenu from 'expi/components/burger-menu';

function LandingLayout(props) {
  const { width,
          /*...rest*/ } = props;

  const menu = ( isWidthUp('md', width) ? <Menu/> : <BurgerMenu/>)
  

  return(
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
        {menu}
  
      <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={route.view}
              />
            ))}
      </Switch>
      <Footer/>
    </div>
  );
}

export default withWidth()(withRouter(withStyles(style)(LandingLayout)));

