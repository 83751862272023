import generalStyle from 'common/styles/general.js';

const style = theme => ({
        labelText:{
            color: '#505050 !important'
        },
        container: {
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: "#EC6669"
        },
        input:{
            width: 0,
            maxWidth: 0,
            willChange: 'width'
        },
        inputExpanded: {
            [theme.breakpoints.up('sm')]: {
                width: '9rem',
                maxWidth: '9rem',
            },
            [theme.breakpoints.up('md')]: {
                width: '13rem',
                maxWidth: '13rem',
            },
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(1),
            willChange: 'width padding margin',
            transition: 'width padding margin',
            transitionDuration: '0.5s',
            transitionTimingFunction: 'ease-in-out',
        },
        rounded:{
            borderRadius: 25,
            backgroundColor: 'rgba(255, 255, 255, 1.0)'
        },
        googleDisclaimer:{
            "& a":{
                color: "rgb(118, 175, 237)",
                textDecoration: "none"
            },
            ...generalStyle(theme).disclaimer
        },
        ...generalStyle(theme)
    });

export default style;
