import React from 'react';

import LandingView from 'views/landing-view';
//import BlogView from 'views/blog-view';
import AboutView from 'views/about-view';
import LandingLayout from 'layouts/landing-layout';
import StandardLayout from 'layouts/standard-layout';


const routes = [
    {
      path: "/about",
      menuItem: true,
      exact: true,
      label: "About Us",
      view: (<AboutView/>),
      layout: (<StandardLayout/>)
    },
    {
      path: "/",
      menuItem: false,
      exact: false,
      label: "Home",
      view: (<LandingView/>),
      layout: (<LandingLayout/>)
    },

    /*
    {
      path: "/blog",
      menuItem: true,
      exact: true,
      label: "Blog",
      view: (<BlogView/>),
      layout: (<StandardLayout/>)
    }
    */
  ];
  
export default routes;