import generalStyle from 'common/styles/general.js';

const style = theme => ({
        menuItem:{
            textDecoration: 'none',
            color: '#FF5B6E !important',
            paddingRight: 50,
        },
        menuItemActive:{
            fontWeight: 'bold',
        },
        burgerLine: {
            borderRadius: 10,
            background: 'rgb(29, 29, 31)',
            zIndex: 100,
            transition: 'transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease',
        },
        burgerFirstLineActive: {
            background:  'rgb(120, 120, 120)',
            opacity: 1,
            transform: 'translate(0px, 8px) rotate(45deg)'
        },
        burgerMidLineActive: {
            background:  'rgb(120, 120, 120)',
            opacity: 0,
            transform: 'scale(0.2, 0.2)'
        },
        burgerLastLineActive: {
            background:  'rgb(120, 120, 120)',
            opacity: 1,
            transform: 'translate(0px, -8px) rotate(-45deg)'
        },
        ...generalStyle(theme)
    });

export default style;
