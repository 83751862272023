import React from 'react';

import {
  NavLink,
  withRouter
} from "react-router-dom";

import classNames from 'classnames';

import withWidth from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import Logo from 'expi/components/logo';
import Box from '@material-ui/core/Box';

import routes from 'routes';
import style from './Styles';

function Menu(props) {

  const { classes, 
          location 
          /*, ...rest*/} = props
  
  const renderMenuItem = (route) => {
    if (route.menuItem) {
      return(<NavLink 
                      to={route.path} 
                      key={route.label}
                      className={ classNames({ 
                        [classes.menuItem]: true,
                        [classes.menuItemActive]: location.pathname === route.path
                      })}>
                {route.label}
              </NavLink>)
    } 
  }

  return (
  <Box
    width={1}
    display={"flex"}
    flexDirection={"row"}
    pt={3}
  > 
    <Box
      display={"flex"}
      flexGrow={1}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
    > 
      <NavLink to="/" className={classNames(classes.menuItem, classes.logoItem)}>
          <Logo/>
        </NavLink>
    </Box>
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"center"}
    > 
        {routes.map(item => (
          renderMenuItem(item)
        ))}
      <a href="mailto:info@expi.ai" className={classes.menuItem} pr={3}>
        Contact Us
      </a>
    </Box>

    <div>
        <span/>
        <span/>
        <span/>
    </div>
  </Box>
  )
}

export default withWidth()(withRouter(withStyles(style)(Menu)));