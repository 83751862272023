import React from 'react'
import classNames from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {InputBase} from '@material-ui/core';
import {Button} from '@material-ui/core';
import {Box} from '@material-ui/core';
import {Typography} from '@material-ui/core';

import Firestore from 'expi/components/firestore'
import Reaptcha from 'reaptcha';


import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import style from './Styles';

class ContactForm extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        
        const { cookies } = props;

        this.state = 
            { 
                betaEmail:      ""
            ,   isRegistering:  false
            ,   isRegistered:   cookies.get("beta_registered")
            ,   isValid:        false
            };

            this.registerFormId = "BetaRegisterForm";
    }

    validate(name, value){
        switch(name) {
            case 'email':
                return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) != null
            default:
                return false
        }
    }

    updateInput(e) {
        const val = e.target.value
        this.setState( { 
                            betaEmail:  val
                        ,   isValid:    this.validate(e.target.name, e.target.value)
                        } ) 
    }
  
    keyPressed(event) {
        if (event.key === "Enter") {
            this.captchaRef.execute();
        }
    }

    onExpire() {
        //  console.log("Captcha expired!");
    }

    onError() {
        //  console.log("Captcha error!");
    }

    async checkServerRecaptcha(val) {
        let response = await fetch('/checkRecaptcha?response=' + encodeURIComponent(val));
        let data = await response.json()
        return data;
    }

    onVerify(data) {
        //  console.log("Captcha verified!");

        this.checkServerRecaptcha(data)
        .then( this.pushData(this.state.betaEmail) )
        .then( this.setState( { isRegistered:  true } ) );       
    };
    
    pushData(e) {
        const { cookies } = this.props;

        Firestore.collection("expi-beta-emails").doc(e).set({})
        .then(function() {
            //console.log("Document successfully written!");
            cookies.set('beta_registered', true, { path: '/' });
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    render() {
        const props = this.props 
        const { classes
              /*, ...rest*/ } = props

        const captchaContainerStyles = 
        { 
            opacity: this.state.isRegistering ? 0.3 : 0,
            transition: 'opacity',
            transitionDuration: '1s',
            transitionTimingFunction: 'ease-in-out',
            transitionDelay: '0.5s'
        }

        const StyledButton = 
            withStyles({
                root: {
                    backgroundColor: '#EC6669',
                    '&:hover': {
                        backgroundColor: '#EC6669',
                    },
                    '&:disabled': {
                        backgroundColor: '#EC6669',
                        color: 'white'
                    },
                    
                    borderRadius: 25,
                    border: 0,
                    color: 'white',
                },
                label: {
                    textTransform: 'capitalize',
                },
                }
            )(Button)
    
        const increasedSize = isWidthUp('lg', props.width)
        const textStyle = { fontSize: increasedSize ? "110%" : "100%" }

        //console.log( "email[" + this.state.betaEmail + "] isValid[" + this.state.isValid + "]" )

        return (
            <div>
                    <Box 
                        display="flex"
                        flexDirection="column" 
                        justifyContent="flex-end" 
                        alignItems="center"
                    > 
                        { !this.state.isRegistered ?
                            (   <Box 
                                    className={ classNames( classes.container, 
                                                            classes.rounded ) }
                                    id={this.registerFormId}
                                   
                                >    
            
                                    <Reaptcha
                                        ref={ e => {this.captchaRef = e} }
                                        sitekey="6Lfu0-gUAAAAAGxIZB600Tn5RFxWv9JJqYLxHHbJ" 
                                        onVerify={ (gresp) => {  console.log( "betaEmail:" + this.state.betaEmail )
                                                                this.onVerify(gresp) } } 
                                        onExpire={this.onExpire}
                                        onError={this.onError}
                                        size="invisible"
                                    />
            
                                    <InputBase
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        placeholder="Your e-mail"
                                        inputProps={{ 'aria-label': 'Your e-mail' }}
                                        className={classNames( classes.input, 
                                                               this.state.isRegistering && classes.inputExpanded )}
                                        onChange={ e => this.updateInput(e) }
                                        onKeyPress={ e => this.keyPressed(e) }
                                        disabled={ this.state.isRegistered }
                                    />
                                    { !this.state.isRegistered ?
                                        (<StyledButton 
                                            variant="contained"
                                            form={this.registerFormId}
                                            className={classes.rounded}
                                            style={textStyle}
                                            disabled={this.state.isRegistering && !this.state.isValid}
                                            onClick={() => {
                                                //console.log("register clicked")
                                                if( this.state.isRegistering ) {
                                                    this.captchaRef.execute();
                                                }
                                                else { 
                                                    this.setState( { isRegistering:  true } )
                                                    //console.log("isRegistering", this.state.isRegistering)
                                                }
                                            }}
                                            disableElevation
                                        >
                                            Get Early Access
                                        </StyledButton>) : null
                                    }
                                </Box>
                            ) : null
                        }
                    </Box>
                    { !this.state.isRegistered ?
                    (
                    <Box display="flex"
                         flexDirection="column" 
                         alignItems="center"
                         pt={1}
                         style={captchaContainerStyles}>
                        <Typography align="center" style={{textAlign: "center"}} variant="caption">
                            This site is protected by reCAPTCHA and the Google <br/> 
                        </Typography>  
                        <Typography align="center" style={{textAlign: "center"}} variant="caption">                              
                            <a href="https://policies.google.com/privacy">Privacy Policy</a>  &nbsp; and &nbsp;
                            <a href="https://policies.google.com/terms">Terms of Service</a>  &nbsp; apply.
                        </Typography>
                    </Box>) : null
                }
            </div> 
        );
    }
}

/*

*/
export default  withWidth()(withStyles(style)(withCookies(ContactForm)));

