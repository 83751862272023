import React from 'react';

import {withRouter,
        Switch,
        Route} from 'react-router-dom';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import classNames from 'classnames';
import Box from '@material-ui/core/Box';

import style from './Styles';
import routes from 'routes';

import Menu from 'expi/components/menu';
import BurgerMenu from 'expi/components/burger-menu';

import topMeshImage from 'images/top_bar_mesh.png';
import animationStyles from './Styles/animation.module.css'; 
import Footer from 'expi/components/footer';



function StandardLayout(props) {
    const { classes,
            width,
            /*...rest*/ } = props;

    const menu = ( isWidthUp('md', width) ? <Menu/> : <BurgerMenu/>)
            
    return(
      <Box
        display={"flex"}
        flexDirection={"column"}
        minWidth={1}
        height={1}>

        <div 
          className={classNames(classes.fixedTopBar)}
          style={{ zIndex: 100}}
        >
          {menu}
        </div>
        <div 
          className={classNames(classes.fixedTopBar, animationStyles.fadeIn)} 
          style={{ backgroundImage: `url(${topMeshImage})`, 
                   backgroundPosition: 'bottom', zIndex: 99,
                   backgroundColor: 'rgba(255, 255, 255, 0.95)',
                   backdropFilter: 'blur(15px)'}}>
        </div>
        <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            overflow={"auto"}
            flexGrow={1}
            p={0}
            m={0}
        >
          <Box 
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            flexGrow={1}>
            <div className={classNames(classes.topBarBreaker)}/>
            <Switch>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      id={index}
                      path={route.path}
                      exact={route.exact}
                      children={route.view}
                    />
                  ))}
            </Switch>
            <Footer/>
          </Box>
        </Box>
      </Box>
    );
  }

export default withWidth()(withRouter(withStyles(style)(StandardLayout)));

