import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';


const config = {
  apiKey:               process.env.REACT_APP_API_KEY,
  authDomain:           process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL:          process.env.REACT_APP_DATABASE_URL,
  projectId:            process.env.REACT_APP_PROJECT_ID,
  storageBucket:        process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId:    process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId:                process.env.REACT_APP_ID,
  measurementId:        process.env.REACT_MEASUREMENT_ID,
};

firebase.initializeApp(config)

const Firestore = firebase.firestore()

export default Firestore;