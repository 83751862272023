import React, {useState, useEffect} from 'react';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';

import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BetaRegister from 'expi/components/beta-register';

import style from './Styles';

import animationStyles from './Styles/animation.module.css'; 

import landingImageLogo from 'images/logo.svg';
import landingImageWoman from 'images/woman+sphere.png';


function LandingViewStandard(props) {
    const { classes,
            width,
            /*...rest*/ } = props;

    const [landingResourcesLoaded, setLandingResourcesLoaded] = useState(false)

    useEffect(() => {
        const img = new Image()
        img.src = landingImageWoman
        img.onload = () => setLandingResourcesLoaded(true)
    }, []);
        
    const loadedPage = (
        <Box
            display={"flex"}
            flexGrow={1}
            flexDirection="column"
            justifyContent="flex-end"
            pl={2}
            pr={2}>

            <div 
                className={classNames(classes.womanSphere, 
                                      animationStyles.womanFadeIn)}
                style={{ backgroundImage: `url(${landingImageWoman})` }}
            />

            <Box
                display={"flex"}
                flexDirection="column"
                flexGrow={1}/>
            <Box 
                minHeight={0.4}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}>
                <Box
                    height={0.5}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"flex-end"}>
                    <Typography 
                        align={"center"}
                        className={classNames(classes.header, 
                                              animationStyles.titleFadeIn)}>
                        CONVERSATION ANALYSIS
                    </Typography>

                    <Typography
                        align={"center"}
                        className={classNames(classes.headerLight, 
                                              animationStyles.subtitleFadeIn)}>
                        REDEFINED
                    </Typography>
                    <Box mt={isWidthUp('lg', width) ? 4 : 2} >
                        <Typography 
                            align={"center"}
                            className={classNames(classes.captionBody, animationStyles.descriptionFadeIn)}>
                            Get insights into communications over voice, video, and other channels.
                        </Typography>
                    </Box>
                </Box>

                <Box
                    mt={isWidthUp('lg', width) ? 8 : 4}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    className={animationStyles.registerFadeIn}
                >
                    <BetaRegister/>
                </Box>
            </Box>
        </Box>
    )


/*

                    flexGrow={1}

                <Box
                    display="flex"
                    flexGrow={1}></Box>
*/
     
    const loadingIndicator = (
        <Box
            display="flex"
            flexGrow={1}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pl={2}
            pr={2}>

                <div className={classes.loadingIndicator}>
                    <img height="50px" src={landingImageLogo} 
                         className={classNames(animationStyles.loadingIndicatorAppear, 
                                    classes.loadingIndicatorImage)} 
                         alt="Expi" />
                    <div className={classNames(animationStyles.loadingIndicatorProgress,
                                    classes.loadingIndicatorOverlay)}></div>
                </div>
            
        </Box>
    )

    return (
        landingResourcesLoaded ? loadedPage : loadingIndicator
        
    )
}

export default withWidth()(withStyles(style)(LandingViewStandard))
