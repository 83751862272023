import generalStyle from 'common/styles/general'

const style = theme => ({
        fixedTopBar: {
            position: 'fixed',
            top: 0,
            [theme.breakpoints.down('sm')]: {
                height: '90px',
                maxHeight: '90px',
            },
            [theme.breakpoints.up('sm')]: {
                height: '140px',
                maxHeight: '140px',
            },
            backgroundSize: 'auto 100%',
            width: '100%'
        },
        topBarBreaker: {
            [theme.breakpoints.up('xs')]: {
                minHeight: '120px',
                height: '120px',
                maxHeight: '120px',
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '150px',
                height: '150px',
                maxHeight: '150px',
            }
        },
        fixedBottomBar: {
            position: 'fixed',
            bottom: 0,
            [theme.breakpoints.down('sm')]: {
                height: '25px',
                maxHeight: '25px',
            },
            [theme.breakpoints.up('sm')]: {
                height: '25px',
                maxHeight: '25px',
            },
            backgroundColor: 'white'
        },
        ...generalStyle(theme)
    });

export default style;
