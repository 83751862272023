import generalStyle from 'common/styles/general.js';

const style = theme => ({
        menuItem:{
            textDecoration: 'none',
            color: '#FF5B6E !important',
            paddingRight: 50,
            paddingTop: 0,
            paddingBottom: 0
        },
        logoItem:{
            paddingLeft: 50,
        },
        menuItemActive:{
            fontWeight: 'bold',
        },
        ...generalStyle(theme)
    });

export default style;
