import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BetaRegister from 'expi/components/beta-register';

import style from './Styles';

import animationStyles from './Styles/animation.module.css'; 

//<div className={classNames(classes.sphereCompact, animationStyles.compactSphereFadeIn)}/>
//<div className={classNames(classes.compactOverlay)}/>

function LandingViewCompact(props) {
    const { classes,
            width,
            /*...rest*/ } = props;

    return (
        <Box
            display="flex"
            flexGrow={1}
            p={3}
            flexDirection={"column"}
            justifyContent={"flex-end"}
        >
            <div className={classNames(classes.womanCompact, animationStyles.compactWomanFadeIn)}/>

            <Box display="flex" flexGrow={2}/>

            <Box 
                //height={0.6}
                mb={6}
                display={"flex"}
                flexGrow={1}
                flexDirection={"column"}
                justifyContent={isWidthUp('sm', width) ? "flex-end": "center"}>


                <Typography 
                    align="center"
                    className={classNames(classes.header, animationStyles.titleFadeIn)}>
                    CONVERSATION ANALYSIS
                </Typography>

                <Typography
                    align="center"
                    className={classNames(classes.headerLight, animationStyles.subtitleFadeIn)}>
                    REDEFINED
                </Typography>
                
                <Box mt={3}>
                    <Typography 
                        align="center"
                        className={classNames(classes.captionBody, animationStyles.descriptionFadeIn)}>
                        Get insights into communications over voice, video, and other channels.
                    </Typography>
                </Box>

                {
                    isWidthUp('sm', width) ?
                        <Box
                            mt={3}
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-end"
                            justifyContent="center"
                            className={animationStyles.registerFadeIn}
                        > 
                            <BetaRegister/>
                        </Box> : null 
                }

            </Box>
        </Box>)
}

/*

*/

export default withWidth()(withStyles(style)(LandingViewCompact))
